import Types from "../types/explored";

export const setVisitedExhibit = (
  locationId: string,
  locationTitle: string,
  areaId: string,
  exhibitId: string
) => {
  return {
    type: Types.SET_VISITED_EXHIBIT,
    payload: {
      locationId,
      locationTitle,
      areaId,
      exhibitId,
    },
  };
};

export const removeExhibit = (locationId: string) => {
  return {
    type: Types.REMOVE_EXHIBIT,
    payload: {
      locationId,
    },
  };
};
