import Types from "../types/app";

//these are action creators, they should only create actions, no side effects

export const setActiveLanguage = code => {
  return {
    type: Types.SET_ACTIVE_LANGUAGE,
    payload: code,
  };
};

export const setActiveLanguageFromDevice = code => {
  return {
    type: Types.SET_LANGUAGE_FROM_DEVICE,
    payload: code,
  };
};

export default {};
