import { find, findIndex, groupBy, isEmpty, without } from "lodash";
import { Dispatch } from "redux";
import * as API from "src/api";
import { captureTopContent } from "src/api";
import Socket from "src/helpers/Socket";
import Utility from "src/helpers/utility";
import { IReduxState } from "../reducers";
import { AreaType } from "../types/areas";
import Types, { ExhibitDataType } from "../types/exhibit";
import ExploredTypes from "../types/explored";
import LocationTypes, { LocationType } from "../types/locations";
import { setCurrentMenuId } from "./app";
import { setActiveLanguageFromDevice } from "./language";

const CACHED_TIME = 30;

const getCachedExhibitData = (state, exhibitId) => {
  return (
    state.topics?.exhibitDetails[exhibitId]?.data || ({} as ExhibitDataType)
  );
};

const getCachedDataModifiedTime = cachedExhibitData => {
  return cachedExhibitData?.modifiedDate?.$date?.$numberLong;
};

const getExhibitLastFetchedTime = (state, exhibitId) => {
  return state.topics?.exhibitDetails[exhibitId]?.lastFetchedTime || Date.now();
};

const isExhibitDataStale = (
  cachedDataModifiedTime,
  comingDataModifiedTime,
  lastFetchedTimeDifference
) => {
  return (
    Number(comingDataModifiedTime) > Number(cachedDataModifiedTime) ||
    lastFetchedTimeDifference >= CACHED_TIME
  );
};

const handleExhibitCaching = async (exhibitId, dispatch, getState) => {
  const state: IReduxState = getState();
  const cachedExhibitData = getCachedExhibitData(state, exhibitId);
  const cachedDataModifiedTime = getCachedDataModifiedTime(cachedExhibitData);
  const exhibitLastFetchedtime = getExhibitLastFetchedTime(state, exhibitId);
  const lastFetchedTimeDifference =
    (Date.now() - exhibitLastFetchedtime) / 1000;

  const res: ExhibitDataType = await API.getExhibitContent(exhibitId);
  const comingDataModifiedTime = res?.modifiedDate?.$date?.$numberLong;

  if (
    isExhibitDataStale(
      cachedDataModifiedTime,
      comingDataModifiedTime,
      lastFetchedTimeDifference
    )
  ) {
    dispatch({
      type: Types.UPDATE_CACHED_EXHIBIT_DATA,
      payload: {
        data: res,
        id: exhibitId,
      },
    });
  }
};

const handleLocationResponse = (
  dispatch,
  locationDetails,
  organizationId,
  getState
) => {
  const location = locationDetails;
  const areas = location?.areas || [];
  const areaOrder = location?.areaOrder || [];

  let orderedAreas =
    areaOrder.length > 0
      ? areaOrder.map(areaId => find(areas, { _id: { $oid: areaId } }))
      : areas;
  orderedAreas = without(orderedAreas, undefined, null, [undefined]);
  const filteredAreas = orderedAreas.filter(
    area => area?.exhibitOrder.length > 0
  );

  let updatedLocation = {
    ...location,
    areas: filteredAreas,
    organizationId,
  };

  updatedLocation = addSecretExhibitsToLocation(updatedLocation, getState);
  let updatedAreasData = {};
  filteredAreas.forEach(areaData => {
    const areaId = areaData?._id?.$oid;
    updatedAreasData = {
      ...updatedAreasData,
      [areaId]: { data: areaData },
    };
  });

  dispatch({
    type: LocationTypes.UPDATE_LOCATION_AND_AREAS,
    payload: {
      location: {
        id: location?._id?.$oid,
        data: updatedLocation,
      },
      areas: updatedAreasData,
    },
  });
};

const handleWhiteLabelData = (dispatch, location) => {
  const whiteLabelData = {} as any;
  whiteLabelData.backgroundImage = location?.mobileBackground || "";
  whiteLabelData.logo = location?.mobileLogo || "";
  whiteLabelData.color = location?.mobileTextColor || "";
  dispatch({ type: Types.SET_WHITE_LABELING, payload: { whiteLabelData } });
};

export const swipeExhibit = (exhibitId: string, locationId?: string) => async (
  dispatch: Dispatch,
  getState: () => IReduxState
) => {
  try {
    dispatch({
      type: Types.SWIPED_EXHIBIT_LOADING_REQUEST,
      payload: exhibitId,
    });
    await getExhibit(exhibitId, false, locationId)(dispatch, getState);
    dispatch({
      type: Types.SWIPED_EXHIBIT_LOADING_COMPLETE,
      payload: exhibitId,
    });
  } catch (error) {}
};

const addSecretExhibitsToLocation = (
  locationDetails: LocationType,
  getState
) => {
  const newLocation = { ...locationDetails };
  const state: IReduxState = getState();
  const locationId = newLocation._id?.$oid;
  const areas = newLocation.areas || [];
  const secretExhibits = Object.values(state.topics.secretExhibits || {});
  const exhibitsOfLoc = groupBy(secretExhibits, "locationId")[locationId];
  if (exhibitsOfLoc && exhibitsOfLoc.length > 0) {
    exhibitsOfLoc.forEach(exhibit => {
      const exhibitId = exhibit.data?._id?.$oid;
      const exhibitAreaId = exhibit.data?.areaId;
      const area = find(areas, { _id: { $oid: exhibitAreaId } });
      const areaIndex = findIndex(areas, { _id: { $oid: exhibitAreaId } });
      const exhibitOrder = area?.exhibitOrder || [];

      if (!find(area?.exhibits, { _id: { $oid: exhibitId } })) {
        area?.exhibits.push(exhibit.data);
      }

      areas[areaIndex].exhibits = exhibitOrder
        .map(exhibitId => find(area?.exhibits, { _id: { $oid: exhibitId } }))
        .filter(Boolean);
      newLocation.areas = areas;
    });
  }
  return newLocation as LocationType;
};

export const getExhibit = (
  exhibitId: string,
  fromScanned: boolean = false,
  currentLocationId?: string
) => async (dispatch: Dispatch, getState) => {
  try {
    const state: IReduxState = getState();
    const activeLanguage = state.language?.activeLanguage;
    const deviceLanguage = state.language?.deviceLanguage;
    const currentExhibitViewsBy = state.app?.currentExhibitViewsBy;
    let locationId = state.app?.currentMenuId || undefined;
    const exhibitData =
      state.topics?.exhibitDetails[exhibitId]?.data || ({} as ExhibitDataType);
    const isConnected = state.app?.connectedToInternet;
    let res: ExhibitDataType;
    let isExhibitAlreadyCached = false;
    let isLocationAlreadyCached = false;
    let locationDetails: LocationType;

    if (!isConnected) {
      Utility.AlertBox("Please connect to internet to view topic");
      return;
    }

    dispatch({ type: Types.GET_EXHIBIT_REQUEST, payload: { id: exhibitId } });

    if (!isEmpty(exhibitData)) {
      res = exhibitData;
      isExhibitAlreadyCached = true;
      await handleExhibitCaching(exhibitId, dispatch, getState);
    } else {
      res = await API.getExhibitContent(exhibitId);
    }

    const organizationId = res?.organizationId;
    const exhibitAreaId = res?.areaId || "";
    const exhibitLocationId =
      state.areas?.areas[exhibitAreaId]?.data?.locationId || "";
    const exhibitLocationData =
      state.location?.locations[exhibitLocationId]?.data ||
      ({} as LocationType);

    if (!locationId) {
      dispatch(setCurrentMenuId(res?.locationId));
      locationId = res?.locationId;
    }

    if (!isEmpty(exhibitLocationData)) {
      isLocationAlreadyCached = true;
      locationDetails = exhibitLocationData;
      await updateCachedLocationData(
        exhibitId,
        organizationId,
        locationId
      )(dispatch, getState);
    } else {
      const reqLocationId = !!currentLocationId ? currentLocationId : undefined;
      const locationRes = await API.getExhibitLocationsDetails(
        exhibitId,
        reqLocationId
      );
      locationDetails = locationRes?.location || {};
    }

    const location = locationDetails;

    if (
      !activeLanguage ||
      !location?.supportedLanguages.includes(activeLanguage) ||
      !isLocationAlreadyCached
    ) {
      dispatch(
        setActiveLanguageFromDevice(
          location?.supportedLanguages.includes(deviceLanguage)
            ? deviceLanguage
            : location?.defaultLanguage
        )
      );
    }

    if (!isLocationAlreadyCached) {
      handleLocationResponse(
        dispatch,
        locationDetails,
        organizationId,
        getState
      );
    }

    if (res.status === 3 || res.status === 4) {
      const secretTopic = {
        name: res.name,
        _id: res._id,
        exhibitImage: res.exhibitImage,
        exhibitImageThumb: res.exhibitImageThumb,
        status: res.status,
      } as ExhibitDataType;

      let areas = location?.areas || ([] as AreaType[]);
      let area = find(areas, { _id: { $oid: exhibitAreaId } });
      let areaIndex = findIndex(areas, { _id: { $oid: exhibitAreaId } });
      const exhibitAlreadyExist = find(area?.exhibits, {
        _id: { $oid: exhibitId },
      });
      if (!exhibitAlreadyExist) {
        area?.exhibits.push(secretTopic);
      }

      let newExhibits = [];
      for (let i = 0; i < area?.exhibitOrder.length; i++) {
        const exhibitId = area.exhibitOrder[i];
        const exhibit = find(area?.exhibits, { _id: { $oid: exhibitId } });
        if (exhibit) {
          newExhibits.push(exhibit);
        }
      }
      area.exhibits = newExhibits;
      location.areas[areaIndex] = area;
    }

    dispatch({
      type: Types.GET_EXHIBIT_SUCCESS,
      payload: {
        data: res,
        locationId: location?._id?.$oid,
        id: exhibitId,
        isExhibitAlreadyCached,
        isSecret: res.status === 3 || res.status === 4,
      },
    });

    Socket.onViewContent({
      areaId: exhibitAreaId,
      exhibitId,
      locationId: location?._id?.$oid,
      organizationId,
    });

    await captureTopContent({
      areaId: exhibitAreaId,
      exhibitId,
      locationId: location?._id?.$oid,
      organizationId,
      viewsBy: currentExhibitViewsBy,
    });

    dispatch({
      type: ExploredTypes.SET_VISITED_EXHIBIT,
      payload: {
        exhibitId,
        locationId: location?._id?.$oid,
        locationTitle: location?.name,
        areaId: res?.areaId,
      },
    });
  } catch (error) {
    dispatch({
      type: Types.GET_EXHIBIT_ERROR,
      payload: { id: exhibitId, error: error.toString() },
    });
    dispatch({ type: Types.FINDING_EXHIBIT_ID, payload: false });
    Utility.AlertBox(`Oops! Looks like this content is currently unavailable`);
  }
};

export const updateCachedLocationData = (
  exhibitId: string,
  organizationId: string,
  currentLocationId?: string
) => async (dispatch, getState) => {
  try {
    const locationRes = await API.getExhibitLocationsDetails(
      exhibitId,
      !!currentLocationId ? currentLocationId : undefined
    );
    const locationDetails: LocationType = locationRes?.location || {};
    handleLocationResponse(dispatch, locationDetails, organizationId, getState);
    handleWhiteLabelData(dispatch, locationDetails);
  } catch (error) {}
};

export const removeTemporaryTopicsFromSecretExhibits = () => async (
  dispatch,
  getState
) => {
  const state: IReduxState = getState();
  const secretExhibits = state.topics?.secretExhibits;
  if (Object.keys(secretExhibits).length !== 0) {
    Object.keys(secretExhibits).forEach(key => {
      if (secretExhibits[key]?.data?.status === 4) {
        delete secretExhibits[key];
      }
    });
  }
};
