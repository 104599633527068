import * as React from "react";
import { useDispatch } from "react-redux";
import { Route, Router } from "react-router-dom";
import history from "src/helpers/history";
import { removeTemporaryTopicsFromSecretExhibits } from "src/store/actions/exhibit";
import BranchSMS from "./BranchSMS";
import Exhibit from "./Exhibit";
import Home from "./Home";

const Screens = () => {
  // before loading the screens, clear the temporary page cache from redux
  const dispatch = useDispatch();
  dispatch(removeTemporaryTopicsFromSecretExhibits());

  return (
    <Router history={history}>
      <Route path="/" exact component={Home} />
      {/* <Route path="/menu/:id?" component={Location} /> */}
      {/* <Route path="/section/:id?" component={Area} /> */}
      <Route path="/topic/:id?" component={Exhibit} />
      <Route path="/download/" component={BranchSMS} />
    </Router>
  );
};

export default Screens;
