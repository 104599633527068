import { groupBy } from "lodash";
import Types, { VisitedExhibitType } from "../types/explored";

const initialState = {
  visitedExhibits: {} as { [exhibitId: string]: VisitedExhibitType },
  visitedExhibitsByLocation: {} as {
    [locationId: string]: VisitedExhibitType[];
  },
};

export default (
  state = initialState,
  action: IReduxAction
): typeof initialState => {
  switch (action.type) {
    case Types.SET_VISITED_EXHIBIT: {
      const timestamp = new Date().toString();
      const { locationId, locationTitle, areaId, exhibitId } = action.payload;
      state.visitedExhibits[exhibitId] = {
        timestamp,
        locationId,
        locationTitle,
        areaId,
        exhibitId,
      };
      let visitedExhibits = state.visitedExhibits;

      return {
        ...state,
        visitedExhibits: visitedExhibits,
        visitedExhibitsByLocation: groupBy(visitedExhibits, "locationId"),
      };
    }
    case Types.REMOVE_EXHIBIT: {
      let visitedExhibitsNew = Object.values(state.visitedExhibits).filter(
        (exhibit: VisitedExhibitType) =>
          exhibit.locationId !== action.payload.locationId
      );
      state.visitedExhibits = Object.fromEntries(
        visitedExhibitsNew.map(exhibit => [exhibit.exhibitId, exhibit])
      );
      return {
        ...state,
        visitedExhibits: Object.fromEntries(
          visitedExhibitsNew.map(exhibit => [exhibit.exhibitId, exhibit])
        ),
        visitedExhibitsByLocation: groupBy(visitedExhibitsNew, "locationId"),
      };
    }
    default:
      return state;
  }
};
